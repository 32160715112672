import store from '@/store';
import { AccountType } from '@/register/registerModel';

export const isAccountSetupRequired = (): boolean => {
    const user = store.getters.module.user;
    if (!user) {
        return false;
    }

    if (user.accountType === AccountType.Unknown || !user.firstName || !user.lastName) {
        return true;
    }

    if(user.accountType === AccountType.Assistant) {
        return !user.hourlyRate ||
            user.skillIds.length === 0

    }

    return false;
}

export const isAccountSetupForbidden = (): boolean => {
    const user = store.getters.module.user;
    if (!user) {
        return true;
    }

    return false;
}
