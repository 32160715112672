import * as axios from 'axios';
import { Address } from '@/panel/userPanelWebServices';
import { CategoryGroup } from '@/serviceClients/categoryWebServices';
import { HobbyOrInterest } from '@/serviceClients/hobbyAndInterestWebServices';
import { EducationLevel, Gender, MaritalStatus, UserLanguage } from '@/accountSetup/accountSetupService';
import { AccountType } from '@/register/registerModel';

const url = `${process.env.VUE_APP_API_URL}/assistants`;

export type UserRatingInfo = {
    averageRating: number,
    givenRatingByUser?: number,
    totalRatings: number,
    canBeRated: boolean,
}

export interface AssistantInfo {
    firstName: string;
    lastName: string;
    accountType: AccountType,
    gender?: Gender;
    maritalStatus?: MaritalStatus;
    height?: number;
    hourlyRate: number | undefined;
    address: Address;
    categories: CategoryGroup[];
    hobbies: HobbyOrInterest[];
    interests: HobbyOrInterest[];
    description: string | undefined;
    photos: { id: string }[];
    userRatingInfo: UserRatingInfo;
    birthDate?: string;
    educationLevel?: EducationLevel;
    educationDetails?: string;
    languages: UserLanguage[];
    verified: boolean;
    referralsNumber: number;
}

export interface AssistantListItem {
    firstName: string;
    lastName: string;
    id: string;
    photoId: string | undefined;
    city: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    verified: boolean;
    description: string;
    categories: CategoryGroup[];
    onlineStatus: number;
    hourlyRate: number | undefined;
    recentlyRegistered: boolean;
}

export function getAssistantInformation(userId: string) {
    return axios.default.get<AssistantInfo>(`${url}/${userId}`);
}
