
import LoggedUser from "@/header/LoggedUser.vue";
import TopbarSearch from "@/header/TopbarSearch.vue";
import { Component, Vue } from "vue-property-decorator";
import store from "./store";
import { AccountType } from "./register/registerModel";
import SelectLanguage from "@/core/translate/SelectLanguage.vue";
import JobOffersRouterLink from "@/header/JobOffersRouterLink.vue";
import { refreshTitle } from "./core";
import Balance from "@/header/Balance.vue";

@Component({
  components: {
    LoggedUser,
    TopbarSearch,
    SelectLanguage,
    JobOffersRouterLink,
    Balance
  },
})
export default class App extends Vue {
  get isLoggedIn() {
    return !!store.getters.module.user;
  }

  get isLoggedInAsAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }

  get isLoggedInAsClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }

  get doShowFooter() {
    return !this.$route.path.includes("conversations") && this.$route.name !== 'Login' && this.$route.name !== 'Register' && this.$route.name !== 'Forgot password';
  }

  get isNavVisible() {
    return this.$route.name !== 'Login' && this.$route.name !== 'Register' && this.$route.name !== 'Forgot password';
  }

  mounted() {
    this.$watch(
      () => [
        store.state.messaging.unreadConversations,
        store.state.module.activeModuleName
      ],
      () => {
        refreshTitle();
      }
    );
  }
}
